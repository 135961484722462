import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContractorsGLMeta } from "../data/seo-meta"
import CallToAction from "../components/call-to-action"

import contractorImg from "../images/cgl/contractor.svg"
import customerDashboard from "../images/cgl/customer_dashboard.svg"
import hotAirBalloon from "../images/common/hot_air_ballon.svg"
import checkIcon from "../images/common/check_icon_green.svg"

const ContractorsGLPage = () => {
  return (
    <Layout>
      <SEO
        description={ContractorsGLMeta.description}
        path={ContractorsGLMeta.path}
        title={ContractorsGLMeta.title}
      />
      <div className="container py-20 md:py-24">
        <div className="grid md:grid-cols-2 gap-20 mb-36">
          <div className="md:pr-24">
            <h1 className="h1 mb-8">Contractors GL</h1>
            <p className="p1">
              Designed for small businesses focused on artisans, general
              contractors, and remodelers. Connect with us to learn about
              becoming a distribution partner for contractors general liability.
            </p>
            <br />
            <CallToAction label="Connect with us" to="/contact/" />
          </div>
          <img src={contractorImg} alt="Contractor working on a house" />
        </div>

        <h2 className="h2 mb-8">Product highlights:</h2>
        <div className={"mb-20"}>
          <table className={"cgl-table"}>
            <thead>
              <tr>
                <th>{/* Spacer */}</th>
                <th>
                  <p>
                    Artisan Contractors
                    <br />
                    General Liability
                  </p>
                  <p>Risk Retention Group (RRG)</p>
                </th>
                <th>
                  <p>
                    Artisan Contractors
                    <br />
                    General Liability
                  </p>
                  <p>Excess & Surplus (E&S)</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>States available</td>
                <td>
                  41 states + D.C. (excluding AK, DE, HI, LA, NY, WA, WI, WV,
                  VA)
                </td>
                <td>
                  Rollout to 48 states (excluding LA & NY)
                </td>
              </tr>
              <tr>
                <td>65+ class codes</td>
                <td className={"check-cell"}>
                  <img src={checkIcon} alt={"check mark"} />
                </td>
                <td className={"check-cell"}>
                  <img src={checkIcon} alt={"check mark"} />
                </td>
              </tr>
              <tr>
                <td>
                  Limits of liability: 1M per occurrence; 2M per aggregate
                </td>
                <td className={"check-cell"}>
                  <img src={checkIcon} alt={"check mark"} />
                </td>
                <td className={"check-cell"}>
                  <img src={checkIcon} alt={"check mark"} />
                </td>
              </tr>
              <tr>
                <td>
                  Eligible loss history: No losses over $5,000 in the last 3
                  years.
                </td>
                <td className={"check-cell"}>
                  <img src={checkIcon} alt={"check mark"} />
                </td>
                <td className={"check-cell"}>
                  <img src={checkIcon} alt={"check mark"} />
                </td>
              </tr>
              <tr>
                <td>
                  Prior insurance not required. New businesses are acceptable.
                </td>
                <td className={"check-cell"}>
                  <img src={checkIcon} alt={"check mark"} />
                </td>
                <td className={"check-cell"}>
                  <img src={checkIcon} alt={"check mark"} />
                </td>
              </tr>
              <tr>
                <td>
                  100% digital and all online + Credit Card payments accepted
                </td>
                <td className={"check-cell"}>
                  <img src={checkIcon} alt={"check mark"} />
                </td>
                <td className={"check-cell"}>
                  <img src={checkIcon} alt={"check mark"} />
                </td>
              </tr>
              <tr>
                <td>Coverage options</td>
                <td>
                  Select – Claims-Made
                  <br />
                  Enhanced – 2 Yr Sunset
                  <br />
                  Premier – Full Occurrence
                </td>
                <td>Full Occurrence</td>
              </tr>
              <tr>
                <td>Rating</td>
                <td>Demotech A</td>
                <td>AM Best A++</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>{/* spacer */}</td>
                <td>
                  <div className={"max-w-[23rem]"}>
                    <CallToAction
                      label={"View Contractors GL RRG Appetite Guide"}
                      external={true}
                      to={
                        "https://info.sliceinsurance.com/contractors-general-liability-appetite-guide-download"
                      }
                    />
                  </div>
                </td>
                <td>
                  <div className={"max-w-[23rem]"}>
                    <CallToAction
                      label={"View Contractors GL E&S Appetite Guide"}
                      external={true}
                      to={
                        "https://info.sliceinsurance.com/contractors-general-liability-e-and-s-appetite-guide-download"
                      }
                    />
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

        <h2 className="h2 mb-8">Agents can enjoy:</h2>
        <div className="grid gap-20 md:grid-cols-2 mb-40">
          <div>
            <ul className="checklist mb-12">
              <li className="p1">Coverage for hard to place classes.</li>
              <li className="p1">
                Quote in 30 seconds & issue under 5 minutes.
              </li>
              <li className="p1">
                Automatically issues policies vs “Request to Bind”.
              </li>
              <li className="p1">
                Pay monthly or annually using a credit card.
              </li>
              <li className="p1">Unlimited instant COIs.</li>
            </ul>
            <CallToAction
              label={"Become a distribution partner"}
              to={"/contact/"}
            />
          </div>
          <img
            className="mx-auto"
            src={customerDashboard}
            alt="Customer dashboard"
          />
        </div>

        <div className="md:text-center mt-48">
          <img className="mx-auto" src={hotAirBalloon} alt="Hot air balloon" />
          <h2 className="h1 my-8">Become a distribution partner.</h2>
          <p className="p1">
            If you are interested in learning more, reach out.
            <br />
            <br />
            <CallToAction label="Connect with us" to="/contact/" />
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default ContractorsGLPage
